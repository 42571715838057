import React from "react";
import { FiPenTool, FiSearch, FiBarChart, FiCode } from "react-icons/fi";
import { Helmet } from "react-helmet-async";

const Services = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.bishwashpantha.com.np/services" />
        <meta
          name="description"
          content="Looking for a highly skilled React developer with 3+ years of experience? I offer hands-on expertise in designing and implementing highly responsive user interface components using React concepts. I excel in translating designs and wireframes into high-quality code with JavaScript and React/S workflows. Contact me today to take advantage of my proven track record of success in delivering cross-browser compatible and accessible web-based solutions."
        />
      </Helmet>
      <div name="services" className=" bg-secondary-dark py-14">
        <div className="max-w-5xl mx-auto flex flex-col justify-center items-center gap-4">
          <h2 className="text-[#E4AA48] font-bold uppercase text-sm">
            Services
          </h2>
          <h1 className="font-bold md:text-3xl text-xl text-[#dadada]">
            What I Am Great At
          </h1>
          <p className="md:w-[780px] md:h-[134px] text-center md:text-[16px] text-sm text-[#dadada] px-4 md:px-0 mt-2 ">
            3+ years experienced React Developer with hands-on experience in
            identifying web-based user interactions along with designing &
            implementing highly responsive user interface components by
            deploying React concepts. Proficient in translating designs &
            wire-frames into high-quality code, and writing application
            interface code via JavaScript and React/S workflows.
          </p>

          <div className="grid  grid-cols-2 md:grid-cols-4 md:gap-7 gap-3 w-full h-[285px] justify-center text-center px-4 md:px-0 mt-2 md:mt-0 hover:text-primary-orange">
            <div className="w-full bg-primary-dark flex flex-col justify-center items-center gap-8 md:gap-10  sm:py-4 ">
              <FiPenTool color="#E4AA48" className="text-4xl md:text-6xl " />
              <span className="text-[#dadada] text-sm md:text-base">
                Web Design
              </span>
            </div>
            <div className="w-full bg-primary-dark flex flex-col justify-center items-center gap-8 md:gap-10 sm:py-4">
              <FiCode color="#dadada" className="text-4xl md:text-6xl" />
              <span className="text-[#dadada] text-sm md:text-base">
                Web Development
              </span>
            </div>
            <div className="w-full bg-primary-dark flex flex-col justify-center items-center gap-8 md:gap-10 sm:py-4">
              <FiSearch color="#E4AA48" className="text-4xl md:text-6xl" />
              <span className="text-[#dadada] text-sm md:text-base">
                Web Research
              </span>
            </div>
            <div className="w-full bg-primary-dark flex flex-col justify-center items-center gap-8 md:gap-10 sm:py-4">
              <FiBarChart color="#dadada" className="text-4xl md:text-6xl" />
              <span className="text-[#dadada] text-sm md:text-base">
                Marketing
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
