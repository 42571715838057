import React from "react";

const Footer = () => {
  return (
      <div className="flex justify-center h-12 border-t-2 border-primary-orange mt-14 pt-3 text-[#dadada] text-xs  md:text-sm">
        <p>Copyright - Bishwash Pantha | All Rights Reserved</p>
      </div>
  );
};

export default Footer;
