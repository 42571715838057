import React from "react";
import HeroImage from "../assets/heroimage.png";
import Resume from "../assets/portfolio/resume.pdf";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.bishwashpantha.com.np/" />
        <meta
          name="description"
          content="Are you looking for a frontend web developer who is an expert in React.js? Look no further! With over 3 years of experience, I have a deep understanding of the React.js framework and can help you build highly responsive user interface components. I excel at translating designs and wireframes into high-quality code, and writing application interface code via JavaScript and React/S workflows. Whether you're looking to build a new web application or enhance an existing one, I have the skills and expertise to deliver results that meet your unique needs. Contact me today to learn more about my React.js development services and how I can help you achieve your web development goals."
        />
      </Helmet>
      <div
        name="home"
        className="max-w-5xl my-16 md:mb-0 mx-auto grid md:grid-cols-3 gap-12 justify-center items-center"
      >
        <div className="flex flex-col items-center md:items-start">
          <div className="md:text-5xl text-3xl text-light-grey font-extrabold flex flex-col items-center md:items-start">
            <h2 className="mb-1 ">Bishwash </h2>
            <h1 className="inline-block">
              Pantha{" "}
              <span
                className="ml-[-8
            px] text-[#E4AA48]"
              >
                .
              </span>
            </h1>
          </div>
          <div className="border-t-2 mt-6 w-[40px] text-[#E4AA48]"></div>
          <div className="flex flex-col md:gap-1 gap-4 md:mt-[14px] mt-7 text-[#dadada] font-semibold">
            <div className="flex md:gap-1 gap-5 ">
              <a
                target="_blank"
                href="https://www.instagram.com/bishwash_pntha/"
                rel="noreferrer"
                className="hover:text-primary-orange"
              >
                Instagram
              </a>
              <span>|</span>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/bishwash-pantha-4682591b4/"
                rel="noreferrer"
                className="hover:text-primary-orange"
              >
                LinkedIn
              </a>
            </div>
            <div className="flex md:gap-1 gap-5">
              <a
                target="_blank"
                href="https://www.facebook.com/bishwash.pantha.7"
                rel="noreferrer"
                className="hover:text-primary-orange"
              >
                Facebook
              </a>
              <span>|</span>
              <a
                target="_blank"
                href="https://github.com/bishwashpantha"
                rel="noreferrer"
                className="hover:text-primary-orange"
              >
                Github
              </a>
            </div>
          </div>
          <a
            className="mt-10 border-2 font-bold p-3 w-[150px] h-12 flex items-center justify-center text-[#E4AA48] hover:bg-primary-orange  hover:text-primary-dark"
            href={Resume}
            download
          >
            Get Resume
          </a>
        </div>
        <div className="md:ml-[-60px] flex flex-col items-center">
          <img src={HeroImage} className="w-[350px]" alt="Hero" />
        </div>
        <div className="flex flex-col gap-6 items-center md:items-start">
          <h2 className="font-bold text-sm text-[#E4AA48] uppercase">
            Introduction
          </h2>
          <h1 className="font-bold md:text-3xl text-xl text-[#dadada]">
            Frontend Engineer
          </h1>
          <p className="md:text-[16px] text-sm text-center md:text-start text-[#dadada] px-4 md:px-0">
            Frontent web developer adapt at building responsive web
            applications. Passionate about learning new technologies, bringing
            ideas to life, and working with dedicated teams to build efficient
            and robust applications suited to the user's needs.
          </p>

          <a
            className="flex items-center gap-2 md:text-[16px] text-sm font-bold text-[#E4AA48] link before:w-28 md:before:w-32 before:-bottom-1"
            href="/"
          >
            <span>Learn More</span>
            {<AiOutlineArrowDown size={"16px"} fontWeight={"700"} />}
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
