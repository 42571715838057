import React from "react";
import { RiCss3Line, RiHtml5Line } from "react-icons/ri";
import {
  DiSass,
  DiJavascript1,
  DiReact,
  DiFirebase,
  DiNodejsSmall,
  DiMysql,
} from "react-icons/di";
import { TbBrandTypescript, TbBrandVercel } from "react-icons/tb";
import { BsBootstrap, BsGit } from "react-icons/bs";
import {
  SiTailwindcss,
  SiRedux,
  SiMongodb,
  SiFigma,
  SiVisualstudiocode,
  SiEslint,
  SiPrettier,
  SiNetlify,
} from "react-icons/si";
import {
  MdCss,
  MdOutlineSettingsBackupRestore,
  MdSettings,
} from "react-icons/md";
import { Helmet } from "react-helmet-async";

const Skills = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.bishwashpantha.com.np/skills" />
        <meta
          name="description"
          content="Looking for a frontend developer to create a responsive, user-friendly, and visually appealing website or application? Look no further than my skills page! With a deep understanding of HTML, CSS, and JavaScript, as well as experience with popular frontend framework Reactjs, I can help bring your ideas to life."
        />
      </Helmet>

      <div
        name="skills"
        className="max-w-5xl mx-auto flex flex-col justify-center items-center py-14 gap-4"
      >
        <h2 className="text-[#E4AA48] font-bold uppercase text-sm">Skills</h2>
        <h1 className="font-bold md:text-3xl text-xl text-[#dadada]">
          What I Use
        </h1>
        <div className="grid grid-rows-1  md:grid-cols-2 gap-5 w-full justify-center text-center mt-10">
          <div className="w-full text-sm md:text-base bg-secondary-dark flex flex-col  items-center gap-10 py-8">
            <h2 className=" text-primary-orange font-bold">Frontend</h2>
            <ul className="text-start text-[#dadada] grid grid-cols-2 gap-y-2 gap-x-6 pl-10">
              <li className="flex gap-2 items-center">
                <RiHtml5Line size={20} />
                <span>HTML5</span>
              </li>
              <li className="flex gap-2 items-center">
                <RiCss3Line size={20} />
                <span>CSS3</span>
              </li>
              <li className="flex gap-2 items-center">
                <DiSass size={20} />
                <span>SASS</span>
              </li>
              <li className="flex gap-2 items-center">
                <SiTailwindcss size={20} />
                <span>Tailwind CSS</span>
              </li>
              <li className="flex gap-2 items-center">
                <BsBootstrap size={20} />
                <span>Bootstrap</span>
              </li>
              <li className="flex gap-2 items-center">
                <MdCss size={20} />
                <span>Material CSS</span>
              </li>
              <li className="flex gap-2 items-center">
                <DiJavascript1 size={20} />
                <span>Javascript ES6</span>
              </li>
              <li className="flex gap-2 items-center">
                <DiReact size={20} />
                <span>React JS</span>
              </li>
              <li className="flex gap-2 items-center">
                <SiRedux size={20} />
                <span>Redux / Redux Toolkit</span>
              </li>
              <li className="flex gap-2 items-center">
                <TbBrandTypescript size={20} />
                <span>TypeScript</span>
              </li>
            </ul>
          </div>
          <div className="w-full text-sm md:text-base bg-secondary-dark flex flex-col py-8 items-center gap-10">
            <h2 className=" text-primary-orange font-bold">Backend</h2>
            <ul className="text-start text-[#dadada] grid grid-cols-2 gap-y-2 gap-x-6 pl-10">
              <li className="flex gap-2 items-center">
                <DiNodejsSmall size={20} />
                <span>NodeJs & Express Js</span>
              </li>
              <li className="flex gap-2 items-center">
                <SiMongodb size={20} />
                <span>MongoDB</span>
              </li>
              <li className="flex gap-2 items-center">
                <DiMysql size={20} />
                <span>MySQL</span>
              </li>

              <li className="flex gap-2 items-center">
                <SiNetlify size={20} />
                <span>Netlify</span>
              </li>
              <li className="flex gap-2 items-center">
                <TbBrandVercel size={20} />
                <span>Vercel</span>
              </li>
              <li className="flex gap-2 items-center">
                <DiFirebase size={20} />
                <span>Firebase & tools</span>
              </li>
            </ul>
          </div>
          <div className="w-full text-sm md:text-base bg-secondary-dark flex flex-col py-8 items-center gap-10">
            <h2 className=" text-primary-orange font-bold">Tools & Software</h2>
            <ul className="text-start text-[#dadada] grid grid-cols-2 gap-y-2 gap-x-8">
              <li className="flex gap-2 items-center">
                <SiFigma size={20} />
                <span>Figma</span>
              </li>
              <li className="flex gap-2 items-center">
                <BsGit size={20} />
                <span>Git</span>
              </li>
              <li className="flex gap-2 items-center">
                <SiVisualstudiocode size={20} />
                <span>Microsoft VSCode</span>
              </li>
              <li className="flex gap-2 items-center">
                <SiEslint size={20} />
                <span>Eslint</span>
              </li>
              <li className="flex gap-2 items-center">
                <SiPrettier size={20} />
                <span>Prettier</span>
              </li>
            </ul>
          </div>
          <div className="w-full text-sm md:text-base bg-secondary-dark flex flex-col py-8 gap-10">
            <h2 className=" text-primary-orange font-bold">
              Methods & Specialities
            </h2>
            <ul className="text-start text-[#dadada] flex flex-col gap-2 pl-12 md:pl-[84px]">
              <li className="flex gap-2 items-center">
                <MdSettings size={20} />
                <span>Agile</span>
              </li>
              <li className="flex gap-2 items-center">
                <MdOutlineSettingsBackupRestore size={20} />
                <span>Scrum</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
