// import React, { useState } from "react";
// import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
// import { Link } from "react-scroll";
// import Logo from "../assets/logo.png";

// const Navbar = () => {
//   const [nav, setNav] = useState(true);

//   const handleNav = () => {
//     setNav(!nav);
//   };

//   return (
//     <div className="flex justify-between items-center h-24 max-w-5xl mx-auto px-4 text-[#dadada]">
//       <a className="link before:-bottom-3 before:w-10" href="/">
//         <img className="w-[52px]" src={Logo} alt="Logo" />
//       </a>

//       {/* Menu */}
//       <ul className="hidden md:flex">
//         <li className="p-4 cursor-pointer link">
//           <Link to="home" smooth={true} duration={500}>
//             Home
//           </Link>
//         </li>
//         <li className="p-4 cursor-pointer link">
//           <Link to="services" smooth={true} duration={500}>
//             Services
//           </Link>
//         </li>
//         <li className="p-4 cursor-pointer link">
//           <Link to="portfolio" smooth={true} duration={500}>
//             Portfolio
//           </Link>
//         </li>
//         <li className="p-4 cursor-pointer link">
//           <Link to="skills" smooth={true} duration={500}>
//             Skills
//           </Link>
//         </li>
//         <li className="p-4 cursor-pointer link">
//           <Link to="contact" smooth={true} duration={500}>
//             Contact
//           </Link>
//         </li>
//       </ul>

//       {/* Hamburger */}
//       <div onClick={handleNav} className="block md:hidden z-10">
//         {!nav ? (
//           <AiOutlineClose size={20} color="#DADADA" />
//         ) : (
//           <AiOutlineMenu size={20} color="#DADADA" />
//         )}
//       </div>

//       {/* Mobile Menu    */}

//       <ul
//         className={
//           !nav
//             ? "md:hidden absolute top-0 left-0 w-full h-screen bg-[#2e2e35] uppercase p-4  text-xl flex flex-col justify-center items-center  ease-in-out duration-500"
//             : "fixed left-[-100%]"
//         }
//       >
//         <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
//           <Link onClick={handleNav} to="home" smooth={true} duration={500}>
//             Home
//           </Link>
//         </li>
//         <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
//           <Link onClick={handleNav} to="services" smooth={true} duration={500}>
//             Services
//           </Link>
//         </li>
//         <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
//           <Link onClick={handleNav} to="skills" smooth={true} duration={500}>
//             Skills
//           </Link>
//         </li>
//         <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
//           <Link onClick={handleNav} to="portfolio" smooth={true} duration={500}>
//             Portfolio
//           </Link>
//         </li>
//         <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
//           <Link onClick={handleNav} to="contact" smooth={true} duration={500}>
//             Contact
//           </Link>
//         </li>
//       </ul>

//       {/* Social Icons */}
//     </div>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link as Links } from "react-router-dom";
import { Link } from "react-scroll";
import Logo from "../assets/logo.png";

const Navbar = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-5xl mx-auto px-4 text-[#dadada]">
      <a className="link before:-bottom-3 before:w-10" href="/">
        <img className="w-[52px]" src={Logo} alt="Logo" />
      </a>

      {/* Menu */}
      <ul className="hidden md:flex">
        <li className="p-4 cursor-pointer link">
          <Link to="home" smooth={true} duration={500}>
            <Links to="/">Home</Links>
          </Link>
        </li>
        <li className="p-4 cursor-pointer link">
          <Link to="services" smooth={true} duration={500}>
            <Links to="/services">Services</Links>
          </Link>
        </li>
        <li className="p-4 cursor-pointer link">
          <Link to="skills" smooth={true} duration={500}>
            <Links to="/skills">Skills</Links>
          </Link>
        </li>
        <li className="p-4 cursor-pointer link">
          <Link to="portfolio" smooth={true} duration={500}>
            <Links to="/portfolio">Portfolio</Links>
          </Link>
        </li>
        <li className="p-4 cursor-pointer link">
          <Link to="contact" smooth={true} duration={500}>
            <Links to="/contact">Contact</Links>
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleNav} className="block md:hidden z-10">
        {!nav ? (
          <AiOutlineClose size={20} color="#DADADA" />
        ) : (
          <AiOutlineMenu size={20} color="#DADADA" />
        )}
      </div>

      {/* Mobile Menu    */}

      <ul
        className={
          !nav
            ? "md:hidden absolute top-0 left-0 w-full h-screen bg-[#2e2e35] uppercase p-4  text-xl flex flex-col justify-center items-center  ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
          <Link onClick={handleNav} to="home" smooth={true} duration={500}>
            <Links to="/">Home</Links>
          </Link>
        </li>
        <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
          <Link onClick={handleNav} to="services" smooth={true} duration={500}>
            <Links to="/services">Services</Links>
          </Link>
        </li>
        <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
          <Link onClick={handleNav} to="skills" smooth={true} duration={500}>
            <Links to="/skills">Skills</Links>
          </Link>
        </li>
        <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
          <Link onClick={handleNav} to="portfolio" smooth={true} duration={500}>
            <Links to="/portfolio">Portfolio</Links>
          </Link>
        </li>
        <li className="py-6 text-[#DADADA] hover:text-primary-orange cursor-pointer">
          <Link onClick={handleNav} to="contact" smooth={true} duration={500}>
            <Links to="/contact">Contact</Links>
          </Link>
        </li>
      </ul>

      {/* Social Icons */}
    </div>
  );
};

export default Navbar;
