import React from "react";
import { FiMail } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";
import {
  AiFillSkype,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineArrowUp,
  AiFillLinkedin,
} from "react-icons/ai";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="canonical"
          href="https://www.bishwashpantha.com.np/contact"
        />
        <meta
          name="description"
          content="Get in touch with me today and let's discuss your web development needs! As a frontend web developer, I am experienced in building responsive and visually stunning web applications using the latest technologies. Whether you need help with website maintenance, updates, or a new project altogether, I am here to help. Contact me via phone, email, or my online form to schedule a consultation and learn more about how I can bring your vision to life."
        />
      </Helmet>
      <div
        name="contact"
        className="relative max-w-5xl mx-auto md:h-[450px] h-[500px]  flex flex-col justify-center items-center md:mt-0 mt-5 py-14 gap-4"
      >
        <h2 className="text-[#E4AA48] font-bold uppercase text-sm">Contact</h2>
        <h1 className="font-bold md:text-3xl text-xl text-[#dadada]">
          Way To Contact Me
        </h1>
        <p className="md:w-[780px]  text-sm md:text-base text-center text-[#dadada] px-4 md:px-0 mt-2 ">
          Let's grab a cup of coffee. Feel free to reach out of any project
          related inquiries and I'll follow up as soon as possible.
        </p>
        <div className="grid  md:grid-cols-3 grid-cols-1 gap-x-2 gap-y-2  md:w-full w-[65%] md:text-base text-sm justify-center text-center mt-10">
          <a
            className="w-full h-full bg-secondary-dark flex items-center  px-4 py-2 gap-4"
            href="mailto:bishwashpantha@gmail.com"
            rel="noreferrer"
          >
            <FiMail fill="#dadada" stroke="#34343B" size={20} />
            <span className="text-primary-orange font-semibold">
              bishwashpantha@gmail.com
            </span>
          </a>
          <a
            target="_blank"
            href="https://twitter.com/bishwash_pantha"
            rel="noreferrer"
            className="w-full h-full bg-secondary-dark flex items-center  px-4 py-2 gap-4"
          >
            <AiOutlineTwitter fill="#dadada" stroke="#34343B" size={20} />
            <span className="text-primary-orange font-semibold">
              bishwash_pantha
            </span>
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/bishwash_pntha/"
            rel="noreferrer"
            className="w-full h-full bg-secondary-dark flex items-center  px-4 py-2 gap-4"
          >
            <AiFillInstagram fill="#dadada" stroke="#34343B" size={20} />
            <span className="text-primary-orange font-semibold">
              bishwash_pntha
            </span>
          </a>
          <a
            target="_blank"
            href="https://join.skype.com/invite/wqEX4g6h40Y2"
            rel="noreferrer"
            className="w-full h-full bg-secondary-dark flex items-center  px-4 py-2 gap-4"
          >
            <AiFillSkype fill="#dadada" stroke="#34343B" size={20} />
            <span className="text-primary-orange font-semibold">
              live:.cid.d7734d0b55ef401b
            </span>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/bishwash-pantha-4682591b4/"
            rel="noreferrer"
            className="w-full h-full bg-secondary-dark flex items-center  px-4 py-2 gap-4"
          >
            <AiFillLinkedin fill="#dadada" stroke="#34343B" size={20} />
            <span className="text-primary-orange font-semibold">
              Bishwash Pantha
            </span>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/bishwash.pantha.7"
            rel="noreferrer"
            className="w-full h-full bg-secondary-dark flex items-center  px-4 py-2 gap-4"
          >
            <FaFacebookF fill="#dadada" stroke="#34343B" size={20} />
            <span className="text-primary-orange font-semibold">
              Bishwash Pantha
            </span>
          </a>
        </div>
        <a
          className="hidden md:border-2 md:border-primary-orange md:p-2 md:absolute md:bottom-[-32px] md:right-[-96px]"
          href="/"
        >
          <AiOutlineArrowUp className="hidden md:text-primary-orange" />
        </a>
      </div>
    </>
  );
};

export default Contact;
