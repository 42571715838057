import React from "react";
import SunauloShopping from "../assets/portfolio/sunaulo.png";
import Apple from "../assets/portfolio/apple.png";
import Omnifood from "../assets/portfolio/omnifood.png";
import Cryptoverse from "../assets/portfolio/cryptoverse.png";
import { Helmet } from "react-helmet-async";

const Portfolio = () => {
  const data = [
    {
      id: 1,
      name: "Sunaulo Shopping",
      image: SunauloShopping,
      github: "",
      live: "https://sunaulo-web.vercel.app/",
    },
    {
      id: 2,
      name: "Apple Clone",
      image: Apple,
      github: "",
      live: "https://appleclone.vercel.app/",
    },
    {
      id: 3,
      name: "CryptoVerse",
      image: Cryptoverse,
      github: "",
      live: "https://cryptoversebybishwash.netlify.app/",
    },
    {
      id: 4,
      name: "OmniFood",
      image: Omnifood,
      github: "",
      live: "https://omnifood-bishwash.netlify.app/",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.bishwashpantha.com.np/portfolio" />
        <meta
          name="description"
          content="Take a look at my impressive portfolio of frontend development projects! As an experienced React developer with a keen eye for design, I've created responsive, user-friendly, and visually appealing websites and applications for a variety of clients. From e-commerce platforms to mobile apps, my projects showcase my expertise in HTML, CSS, JavaScript, and popular frameworks like React, Angular, and Vue.js. Contact me today to learn more about how I can help take your online presence to the next level!"
        />
      </Helmet>
      <div name="portfolio" className=" bg-secondary-dark">
        <div className="max-w-5xl mx-auto  flex flex-col justify-center items-center py-14  gap-4">
          <h2 className="text-[#E4AA48] font-bold uppercase text-sm">
            Portfolio
          </h2>
          <h1 className="font-bold  md:text-3xl text-xl text-[#dadada]">
            My Projects
          </h1>

          {/* container for projects */}
          <div className="grid w-[70%] md:w-full sm:grid-cols-1 md:grid-cols-2 gap-4 mt-10">
            {/* Gird Item */}
            {data.map((item, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: "cover",
                }}
                className=" shadow-lg w-full group shadow-[#222] container rounded-md 
              flex justify-center text-center items-center mx-auto content-div transition ease-in-out hover:-translate-y-1 hover:scale-y-40  duration-100"
              >
                {/* Hover effect for images */}
                <div className="opacity-0 group-hover:opacity-100 ">
                  <span className="text-2xl font-bold text-primary-dark tracking-wider ">
                    {item.name}
                  </span>
                  <div className="pt-8 text-center ">
                    <a href={item.live} target="_blank" rel="noreferrer">
                      <button
                        className="text-center rounded-lg px-4  m-2
                       text-light-grey border-2 border-light-grey hover:bg-light-grey hover:text-primary-orange hover:border-[#fff0] font-bold text-lg"
                      >
                        Live
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
